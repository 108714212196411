<template>
	<div class="component-container">
		<div v-if="!value && !loading" class="d-flex">
			<v-btn class="btn-file" small color="warning">
				Seleziona file json<input accept="application/json" type="file" @change="onFileChange">
			</v-btn>
		</div>
		<div v-if="value">
			<pre v-if="enablePreview" class="preview">{{ value }}</pre>
			<v-btn small color="error" class="mt-5" @click.prevent.stop="removeFile">
				Carica un diverso file
			</v-btn>
		</div>
		<div v-if="loading" class="loading">
			loading... {{ progress }}
		</div>
		<div v-if="error" class="errorMsg">
			{{ error }}
		</div>
		<v-textarea v-if="debug" v-model="debug" class="preview" />
	</div>
</template>

<script>
// ***********************************************************************************
// ** componente di upload specializzato nei file json
// ***********************************************************************************
export default {
	name: 'JsonUploadComponent',
	props: {
		// 
		value: {
			type: String,
			required: true,
			default: () => {return ''}
		},
		enablePreview: {
			type: Boolean,
			default: () => {return true}
		} 
	},
	data: function () {
		return {
			dataFileJson: null,
			loading: false,
			progress: '',
			error: '',
			debug: ''
		}
	},
	mounted: function () {
	},
	methods: {
		onFileChange(e) {
			this.error = ''
			console.warn(e)
			var files = e.target.files || e.dataTransfer.files
			if (!files.length) return
			this.loadFile(files[0])
		},
		loadFile(file) {
			var reader = new FileReader()
			var _this = this
			reader.onload = (e) => {
				try {
					JSON.parse(e.target.result)
					_this.$emit('input', e.target.result)
				} catch (e) {
					console.error(e)
					_this.error = 'Errore: file non valido'
				}
			}
			reader.onloadstart = () => {
				_this.loading = true
			}
			reader.onloadend = () => {
				_this.loading = false
			}
			reader.onprogress = (e) => {
				if (e.lengthComputable) {
					_this.progress = parseInt(((e.loaded / e.total) * 100), 10) + '%' 
				}
			}
			reader.readAsText(file)
		},
		removeFile() {
			this.$emit('input', '')
		}
	}
}
</script>

<style scoped lang="less">
.preview {
	height:200px !important; 
	overflow-y: auto !important;
	overflow-x: hidden !important;
	font-size:80%;
	font-size: 11px;
	line-height: 12px;
	background-color: #222; 
	padding: 2px; 
	color: #44ff44;
	box-shadow: 1px 1px 10px inset rgba(0,0,0,0.5);
	border-radius: 4px;
}
.component-container{
    position: relative;
    .btn-file {
        position: relative;
        overflow: hidden;
    }
    .btn-file input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
        display: block;
    }    
    
    .loading{
        font-style: italic;
    }
    .errorMsg{
        display: inline-block;
        padding: 5px;
        margin-top:10px;
        background-color:yellow;
        color:red;
        border-radius: 2px;
        box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
        font-weight: bold;
    }
}
</style>
